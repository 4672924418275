import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  SearchDocumentType,
  ISearchProductDocument,
} from '@wix/client-search-sdk';

import {
  ISampleLayoutProps,
  IListLayoutItemProps,
  OnDocumentClick,
} from '../Layout.types';
import { Title } from '../Title';
import { ILayoutItem } from '../LayoutItem.types';
import { ListLayoutItem } from '../ListLayout';
import { GridLayoutItem } from '../GridLayout';
import { ViewAllButton } from '../ViewAllButton';

import {
  LIST_LAYOUT_DEFAULT_THUMBNAIL_HEIGHT,
  LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH,
} from '../ListItemThumbnail';

import {
  EventListItem,
  EventListItemRenderMode,
} from '../EventList/EventListItem';

import { st, classes } from './SampleLayout.st.css';

const THUMBNAIL_WIDTH = {
  DESKTOP: 105,
  MOBILE: 105,
};

const THUMBNAIL_HEIGHT = {
  DESKTOP: 70,
  MOBILE: 70,
};

const getThumbnailWidth = (isMobile: boolean, isFluid: boolean) =>
  isFluid
    ? LIST_LAYOUT_DEFAULT_THUMBNAIL_WIDTH
    : isMobile
    ? THUMBNAIL_WIDTH.MOBILE
    : THUMBNAIL_WIDTH.DESKTOP;

const getThumbnailHeight = (isMobile: boolean, isFluid: boolean) =>
  isFluid
    ? LIST_LAYOUT_DEFAULT_THUMBNAIL_HEIGHT
    : isMobile
    ? THUMBNAIL_HEIGHT.MOBILE
    : THUMBNAIL_HEIGHT.DESKTOP;

type ISample = ISampleLayoutProps['results'][0];
enum LayoutType {
  grid = 'grid',
  list = 'list',
  events = 'events',
}

const typeLayoutMap: Record<SearchDocumentType, LayoutType | null> = {
  [SearchDocumentType.All]: null,
  [SearchDocumentType.Pages]: LayoutType.list,
  [SearchDocumentType.Products]: LayoutType.grid,
  [SearchDocumentType.Blogs]: LayoutType.list,
  [SearchDocumentType.Forums]: LayoutType.list,
  [SearchDocumentType.Bookings]: LayoutType.list,
  [SearchDocumentType.Events]: LayoutType.events,
  [SearchDocumentType.Programs]: LayoutType.list,
};

export const SampleLayout: React.FC<ISampleLayoutProps> = (props) => {
  const { isEditorX, isMobile } = useEnvironment();

  const getRenderItemProps = (
    item: ILayoutItem,
    index: number,
  ): IListLayoutItemProps & { key: any } => {
    const onLinkClick: OnDocumentClick = (e, clickOrigin) =>
      props.onItemLinkClick?.(e, item, index, clickOrigin);

    const { formatCurrency, formatDate, formatTime, t } = props;

    return {
      formatCurrency,
      formatDate,
      formatTime,
      item,
      key: item.id,
      onLinkClick,
      t,
    };
  };

  const renderListLayoutItem = (item: ILayoutItem, index: number) => {
    return (
      <ListLayoutItem
        {...getRenderItemProps(item, index)}
        thumbnailWidth={getThumbnailWidth(isMobile, isEditorX)}
        thumbnailHeight={getThumbnailHeight(isMobile, isEditorX)}
      />
    );
  };

  const renderGridLayoutItem = (item: ILayoutItem, index: number) => {
    const onProductAddToCartClick: React.MouseEventHandler = () =>
      props.onProductAddToCart?.(item as ISearchProductDocument);

    return (
      <GridLayoutItem
        {...getRenderItemProps(item, index)}
        onProductAddToCartClick={onProductAddToCartClick}
      />
    );
  };

  const renderEventsLayoutItem = (item: ILayoutItem, index: number) => {
    return (
      <EventListItem
        {...getRenderItemProps(item, index)}
        thumbnailWidth={getThumbnailWidth(isMobile, isEditorX)}
        thumbnailHeight={getThumbnailHeight(isMobile, isEditorX)}
        renderMode={
          isEditorX
            ? EventListItemRenderMode.default
            : EventListItemRenderMode.condensed
        }
      />
    );
  };

  const renderSampleGroup = (sample: ISample) => {
    const { onViewAllClick } = props;

    if (!sample.documents.length) {
      return;
    }

    const layoutType = typeLayoutMap[sample.documentType] || LayoutType.list;
    const renderItem = {
      grid: renderGridLayoutItem,
      list: renderListLayoutItem,
      events: renderEventsLayoutItem,
    }[layoutType];
    const sampleGroupLabel = sample.documentType?.split('/').pop();

    return (
      <div
        data-hook={`sample-layout-group-${sampleGroupLabel}`}
        className={classes.group}
        key={sample.documentType}
      >
        <h2 className={classes.groupHeader}>
          <Title
            data-hook="sample-layout-group-title"
            title={sample.title}
            onClick={(e) => onViewAllClick(e, sample.documentType)}
            url={sample.url}
          />
        </h2>

        <ul
          data-hook="sample-layout-group-items"
          className={st(classes.groupItems, {
            layout: layoutType,
          })}
        >
          {sample.documents.map(renderItem)}
        </ul>
        <ViewAllButton
          url={sample.url}
          onClick={(e) => onViewAllClick(e, sample.documentType)}
          data-hook="sample-layout-group-view-all"
        />
      </div>
    );
  };

  return (
    <div
      className={st(classes.root, {
        fixed: !isEditorX,
        fluid: isEditorX,
        mobileView: isMobile,
      })}
      data-hook="sample-layout"
      tabIndex={-1}
      role="region"
      aria-label={props.label}
    >
      {props.results.map(renderSampleGroup)}
    </div>
  );
};
