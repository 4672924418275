import { SearchDocumentType } from '@wix/client-search-sdk';

export const DOCUMENT_TYPE_TRANSLATIONS: Record<SearchDocumentType, string> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all',
  [SearchDocumentType.Pages]: 'searchResults.tabs.label.pages',
  [SearchDocumentType.Products]: 'searchResults.tabs.label.products',
  [SearchDocumentType.Blogs]: 'searchResults.tabs.label.blogs',
  [SearchDocumentType.Forums]: 'searchResults.tabs.label.forums',
  [SearchDocumentType.Bookings]: 'searchResults.tabs.label.bookings',
  [SearchDocumentType.Events]: 'searchResults.tabs.label.events',
  [SearchDocumentType.Programs]: 'searchResults.tabs.label.programs',
};

export const DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT: Record<
  SearchDocumentType,
  string
> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all.withCount',
  [SearchDocumentType.Pages]: 'searchResults.tabs.label.pages.withCount',
  [SearchDocumentType.Products]: 'searchResults.tabs.label.products.withCount',
  [SearchDocumentType.Blogs]: 'searchResults.tabs.label.blogs.withCount',
  [SearchDocumentType.Forums]: 'searchResults.tabs.label.forums.withCount',
  [SearchDocumentType.Bookings]: 'searchResults.tabs.label.bookings.withCount',
  [SearchDocumentType.Events]: 'searchResults.tabs.label.events.withCount',
  [SearchDocumentType.Programs]: 'searchResults.tabs.label.programs.withCount',
};

export const DOCUMENT_TYPE_ACCESSIBILITY_LABEL: Record<
  SearchDocumentType,
  string
> = {
  [SearchDocumentType.All]: 'searchResults.tabs.label.all.accessibilityLabel',
  [SearchDocumentType.Pages]:
    'searchResults.tabs.label.pages.accessibilityLabel',
  [SearchDocumentType.Products]:
    'searchResults.tabs.label.products.accessibilityLabel',
  [SearchDocumentType.Blogs]:
    'searchResults.tabs.label.blogs.accessibilityLabel',
  [SearchDocumentType.Forums]:
    'searchResults.tabs.label.forums.accessibilityLabel',
  [SearchDocumentType.Bookings]:
    'searchResults.tabs.label.bookings.accessibilityLabel',
  [SearchDocumentType.Events]:
    'searchResults.tabs.label.events.accessibilityLabel',
  [SearchDocumentType.Programs]:
    'searchResults.tabs.label.programs.accessibilityLabel',
};
