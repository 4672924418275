import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  Image,
  ImageLoadingBehaviorOptions,
  ImageResizeOptions,
} from 'wix-ui-tpa';

import { stripMarkTags } from '../stripMarkTags';

import { classes } from './Thumbnail.st.css';

interface IThumbnailProps {
  height: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
  src: string;
  title: string;
  url?: string;
  width: number;
}

interface IDataHookAttribute {
  'data-hook'?: string;
}

export const Thumbnail: React.FC<IThumbnailProps> = ({
  height,
  onClick,
  src,
  title,
  url,
  width,
}) => {
  const { isEditorX } = useEnvironment();

  const commonProps: React.HTMLAttributes<HTMLAnchorElement | HTMLSpanElement> &
    IDataHookAttribute = {
    'aria-hidden': true,
    'data-hook': 'item-thumbnail',
    onClick,
    tabIndex: -1,
    title: stripMarkTags(title),
  };

  const image = (
    <Image
      alt={commonProps.title}
      aspectRatio={isEditorX ? width / height : undefined}
      fluid={isEditorX}
      height={height}
      loadingBehavior={ImageLoadingBehaviorOptions.blur}
      resize={ImageResizeOptions.cover}
      src={src}
      width={width}
    />
  );

  const thumbnail = url ? (
    <a {...commonProps} href={url}>
      {image}
    </a>
  ) : (
    <span {...commonProps}>{image}</span>
  );

  return isEditorX ? (
    // This part is needed for SSR in responsive. `Image` component in fluid mode renders in
    // SSR with `height: 0` and which causes flicker. To solve that issue added bounding
    // container with height and width based on aspect ratio. This is workaround. I think it
    // should be fixed on TPA side.
    <div
      className={classes.thumbnailAspectRatio}
      style={{
        paddingTop: `${(height / width) * 100}%`,
      }}
    >
      <div className={classes.thumbnailContent}>{thumbnail}</div>
    </div>
  ) : (
    thumbnail
  );
};
