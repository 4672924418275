import { IDocumentImage } from '@wix/client-search-sdk';

export function getWixImageUrl(image: IDocumentImage): string | undefined {
  if (!image) {
    return;
  }

  if ('src' in image) {
    return image.src;
  } else if ('name' in image) {
    return image.name;
  }
}
