import React from 'react';

import { ISearchResultsControllerProps } from '../../platform/searchResultsControllerStore';
import { Widget } from '../Widget';
import { AppSettingsContext } from '../../contexts';
import { useHighlightStyle } from './useHighlightStyle';
import { IHostProps } from '@wix/yoshi-flow-editor';

export interface IWidgetRootProps extends ISearchResultsControllerProps {
  id: string;
  host: IHostProps;
}

export const WidgetRoot: React.FC<IWidgetRootProps> = React.memo((props) => {
  console.log('WidgetRoot:render', props); // eslint-disable-line

  const {
    apiErrorDetails,
    id,
    isDemoContent,
    locale,
    locationQuery,
    currency,
    onDocumentClick,
    onDocumentTypeChange,
    onPageChange,
    onProductAddToCart,
    onProductFacetsFilterChange,
    onProductFacetsFilterReset,
    onQuerySubmit,
    onSortChange,
    productFacets,
    documentTypes,
    searchSamples,
    searchRequest,
    searchRequestStatus,
    searchResponse,
    searchResponseTotals,
    searchResultsAbsoluteUrl,
    selectedSortOption,
    appSettings,
    viewMode,
    host,
  } = useHighlightStyle(props);

  return (
    <div className={id}>
      <AppSettingsContext.Provider value={appSettings}>
        <Widget
          apiErrorDetails={apiErrorDetails}
          documentTypes={documentTypes}
          isDemoContent={isDemoContent}
          locale={locale}
          locationQuery={locationQuery}
          currency={currency}
          onProductAddToCart={onProductAddToCart}
          onProductFacetsFilterChange={onProductFacetsFilterChange}
          onDocumentClick={onDocumentClick}
          onDocumentTypeChange={onDocumentTypeChange}
          onProductFacetsFilterReset={onProductFacetsFilterReset}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          onQuerySubmit={onQuerySubmit}
          searchRequest={searchRequest}
          searchRequestStatus={searchRequestStatus}
          searchResponse={searchResponse}
          searchResponseTotals={searchResponseTotals}
          searchResultsAbsoluteUrl={searchResultsAbsoluteUrl}
          searchSamples={searchSamples}
          selectedSortOption={selectedSortOption}
          viewMode={viewMode}
          productFacets={productFacets}
          host={host}
        />
      </AppSettingsContext.Provider>
    </div>
  );
});
