import { Experiments } from '@wix/yoshi-flow-editor';
import { AppSettings } from '@wix/search-settings-client';

import { Spec } from '../../../../../lib/specs';
import { IWidgetProps } from '../Widget';

export const isProductCollectionsFacetVisible = ({
  collections,
  filter,
}: IWidgetProps['productFacets']): boolean => {
  return !!collections.length || !!filter.collections?.length;
};

export const isProductPriceFacetVisible = (
  appSettings: AppSettings,
  experiments: Experiments,
): boolean => {
  return (
    !experiments.enabled(Spec.ProductPriceVisibility) ||
    appSettings.isProductPriceVisible
  );
};
