import * as React from 'react';

import {
  InjectedEnvironmentProps,
  InjectedExperimentsProps,
  withEnvironment,
  withExperiments,
} from '@wix/yoshi-flow-editor';

import { Title } from '../Title';
import { Description } from '../Description';
import { IListLayoutItemProps } from '../Layout.types';
import { ListItemThumbnail } from '../ListItemThumbnail';
import { getWixImageUrl } from '../getWixImageUrl';

import { st, classes } from './ListLayoutItem.st.css';

type ListLayoutItemProps = IListLayoutItemProps &
  InjectedEnvironmentProps &
  InjectedExperimentsProps;

class ListLayoutItemComp extends React.Component<ListLayoutItemProps> {
  static defaultProps: Partial<IListLayoutItemProps> = {
    renderContent: (props: IListLayoutItemProps) => {
      const { renderDescription, renderTitle } = props;
      return (
        <div className={classes.content}>
          {renderTitle ? renderTitle(props) : null}
          {renderDescription ? renderDescription(props) : null}
        </div>
      );
    },
    renderTitle: ({ item, onLinkClick }: IListLayoutItemProps) => {
      return (
        <Title
          onClick={(e) => onLinkClick?.(e, 'title')}
          title={item.title}
          url={item.url}
        />
      );
    },
    renderDescription: ({ item }: IListLayoutItemProps) => {
      return <Description description={item.description} />;
    },
  };

  render() {
    const {
      environment,
      renderContent,
      item,
      thumbnailHeight,
      thumbnailWidth,
      onLinkClick,
    } = this.props;
    const { isMobile, isEditorX } = environment;
    const { image, url, title } = item;
    const src = getWixImageUrl(image);

    return (
      <li
        data-hook="list-layout-item"
        className={st(classes.root, {
          fixed: !isEditorX,
          fluid: isEditorX,
          mobileView: isMobile,
        })}
      >
        {src && (
          <ListItemThumbnail
            height={thumbnailHeight}
            onLinkClick={onLinkClick}
            src={src}
            title={title}
            url={url}
            width={thumbnailWidth}
          />
        )}
        {renderContent ? renderContent(this.props) : null}
      </li>
    );
  }
}

export const ListLayoutItem = withExperiments(
  withEnvironment(ListLayoutItemComp),
);
