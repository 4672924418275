export enum Spec {
  ImageResizeOptions = 'specs.siteSearch.ImageResizeOptions',
  MobileSuggestions = 'specs.siteSearch.MobileSuggestions',
  NewSearchPageUrlSlug = 'specs.siteSearch.NewSearchPageUrlSlug',
  NewStoresAppDefId = 'com.wixpress.search.commons.specs.NewStoresAppDefIdSpec',
  ProductPriceVisibility = 'specs.siteSearch.ProductPriceVisibility',
  RemoveLabelOverrideToggle = 'specs.siteSearch.RemoveLabelOverrideToggle',
  SeoHiddenPages = 'specs.siteSearch.SeoHiddenPages',
  UrlQueryParams = 'specs.siteSearch.UrlQueryParams',
}
