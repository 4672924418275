import {
  ILocationSearchRequest,
  IEncodedSearchPathParams,
  IEncodedSearchQueryParams,
} from '../location.types';

import {
  encodeQuery,
  encodeQueryToPath,
  encodeDocumentType,
  encodePage,
  encodeSort,
  encodeProductCollections,
  encodeProductPrice,
} from './serializers';

export function encodeSearchRequestToPathParams(
  request: ILocationSearchRequest,
): IEncodedSearchPathParams {
  const q = encodeQueryToPath(request.query);
  const qc = encodeDocumentType(request.documentType);
  const page = encodePage(request.page);

  return {
    ...(q !== undefined && { q }),
    ...(qc !== undefined && { qc }),
    ...(page !== undefined && { page }),
  };
}

export function encodeSearchRequestToQueryParams(
  request: ILocationSearchRequest,
): IEncodedSearchQueryParams {
  const q = encodeQuery(request.query);
  const type = encodeDocumentType(request.documentType);
  const page = encodePage(request.page);
  const sort = encodeSort(request.sort);
  const collections = encodeProductCollections(request.collections);
  const price = encodeProductPrice({
    minPrice: request.minPrice,
    maxPrice: request.maxPrice,
  });

  return {
    ...(q !== undefined && { q }),
    ...(type !== undefined && { type }),
    ...(page !== undefined && { page }),
    ...(collections !== undefined && { collections }),
    ...(price !== undefined && { price }),
    ...(sort !== undefined && { sort }),
  };
}
